<template>
  <div class="Goods">
    <div class="Goods_box">
      <div class="Goods_list">
        <div
          class="Goods_item"
          v-for="(item, index) in DetailList"
          :key="index"
          :class="`lv${item.level}`">
          <div v-if="item.odds_percent" class="box-price">
            <div class="dura_alias">概率:{{ item.odds_percent }}</div>
          </div>
          <div class="Goods_pic">
            <img :src="item.cover" alt="" />
          </div>
          <div
            class="elips1"
            style="font-size: 0.1rem; margin-top: 0rem; margin-bottom: 0.03rem">
            {{ item.name }}
          </div>
          <div style="font-size: 0.1rem; margin-bottom: 0.03rem">
            {{ item.dura_alias == "无" ? "" : item.dura_alias }}
          </div>
          <div class="odds_percent">
            <div class="d-flex align-items-center">
              <Crarity
                :size="0.1"
                :price="
                  (item.bean * 6).toFixed(0) === '0'
                    ? 1
                    : (item.bean * 6).toFixed(0)
                " />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="showPrice" style="display: flex;align-items: center;justify-content: center;width:100%;color:rgb(236, 177, 5);font-size: 0.12rem;">
      合计：
      <Cprice :size="0.12" :price="totalMoney" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Goods",
  props: {
    DetailList: {
      //箱内物品列表
      type: Array,
    },
    id: {
      //宝箱id
      type: String,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Switch: "2", //掉落/物品
      historylist: [], //历史掉落列表
      timer: null,
    };
  },
  computed: {
    totalMoney() {
      let totalMoney = 0;
      this.DetailList.forEach((item) => {
        totalMoney += item.bean * 1;
      });
      return totalMoney.toFixed(2);
    },
  },
  //activated
  created() {
    this.GetUnpackingRecord();
    this.SetUnpackingRecord();
  },
  methods: {
    //历史掉落
    GetUnpackingRecord() {},
    SetUnpackingRecord() {
      setInterval(() => {
        this.GetUnpackingRecord();
      }, 20000);
    },
  },

  watch: {
    id(v) {
      this.GetUnpackingRecord();
    },
  },
};
</script>

<style lang="scss">
// .title-class {
//   width: 14rem;
//   padding: 0.1rem;
// }

.Goods {
  width: 100%;
  // padding: 0.1rem;
  box-sizing: border-box;
  // margin-top: 0.1rem;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;

  .Goods_box {
    width: 100%;
    padding: 0.08rem 0;
    .Goods_nav {
      width: 100%;
      display: flex;
      color: #fff;

      p {
        width: 1.2rem;
        height: 0.4rem;
        background: #fff;
        color: #000;
        text-align: center;
        line-height: 0.4rem;
        font-size: 0.16rem;
        cursor: pointer;

        &.active {
          background: #ffba2e;
        }
      }
    }

    .history_list {
      width: 100%;
      display: flex;
      padding: 5px 0;
      // flex-wrap: wrap;
      border-radius: 0 4px 4px 4px;
      margin-top: 20px;
      overflow-x: hidden;
      overflow-y: hidden;

      .history_item {
        flex-shrink: 0;
        width: 0.8rem;
        text-align: center;
        font-size: 12px !important;
        color: #fff;
        margin-bottom: 10px;
        margin-right: 5px;
        padding-bottom: 20px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        /*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
        background-size: cover;
        // border-radius: 6px;
        position: relative;
        cursor: pointer;

        .ceng {
          position: absolute;
          width: 50px;
          height: 50px;
          text-align: center;
          transition: 0.3s;
          right: -15px;

          .C_pic {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin: 40px auto 10px;

            img {
              width: 100%;
              height: 100%;
            }

            p {
              font-size: 12px;
              color: #fff;
            }
          }
        }

        // &:hover {
        // 	.ceng {
        // 		top: 0
        // 	}
        // }

        .history_pic {
          width: 100%;
          height: 100px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;

          img {
            max-width: 100%;
            vertical-align: middle;
          }
        }

        p {
          width: 100%;
          padding: 0 3px;
          box-sizing: border-box;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .Goods_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // max-height: 1.7rem;
      // overflow-y: scroll;
      .Goods_item {
        width: 1.05rem;
        height: 1.12rem;
        padding: 0.03rem 0.05rem 0.05rem;
        // padding-bottom: 0.04rem;
        margin: 0 0.032rem;
        text-align: center;
        color: #fff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        margin-bottom: 0.08rem;

        .box-price {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.05rem;
        }

        .odds_percent {
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: left;
          // padding-left: 10px;
          box-sizing: border-box;
          color: #dea61f;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dura_alias {
          // text-align: left;
          // padding-right: 10px;
          box-sizing: border-box;
          color: #fff;
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: center;
        }

        .Goods_pic {
          width: 0.6rem;
          height: 0.46rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          // padding: 0.1rem 0;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }

        p {
          width: 100%;
          box-sizing: border-box;
          font-size: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 0.18rem;
          margin-bottom: 0.08rem;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #75cd66;
          font-size: 0.1rem;
          margin: 5px 0 0;

          img {
            width: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
