import pro0 from "@/assets/images/OpenBox/pro0.png";
import pro1 from "@/assets/images/OpenBox/pro1.png";
import pro2 from "@/assets/images/OpenBox/pro2.png";
import pro3 from "@/assets/images/OpenBox/pro3.png";
export const proBList = [pro0, pro1, pro2, pro3];
export const tableRecordList = [
  // {
  //   title: "掉落时间",
  //   width: "40%",
  //   index: "created_at",
  //   center: "center",
  //   render: true,
  // },
  {
    title: "获得玩家",
    width: "45%",
    index: "get_user",
    center: "center",
    render: true,
  },
  // {
  //   title: "游戏类型",
  //   width: "32%",
  //   index: "type",
  //   center: "center",
  //   render: true,
  // },
  {
    title: "道具名称",
    width: "35%",
    index: "name",
    center: "center",
    render: true,
  },
  {
    title: "品质",
    width: "10%",
    index: "bean",
    center: "center",
    render: true,
  },
];
export const tableData = {
  // total: 15,
  list: [
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
  ],
};
export const statisticsList = [
  {
    title: "道具统计",
    width: "70%",
    index: "get",
    center: "center",
    render: true,
  },
  {
    title: "距离上次开出",
    width: "28%",
    index: "num",
    center: "center",
    rightVal: "次",
  },
  {
    title: "总开出",
    width: "15%",
    index: "total",
    center: "center",
    rightVal: "次",
  },
];
export const statisticsData = {
  total: 15,
  list: [
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      num: 10000,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
  ],
};
export const typeList = [
  {
    title: "道具类型",
    width: "30%",
    index: "type",
    center: "center",
    render: true,
  },
  {
    title: "理论概率",
    width: "25%",
    index: "theory",
    center: "center",
    rightVal: "%",
  },
  {
    title: "开出个数",
    width: "25%",
    index: "openNum",
    center: "center",
  },
  {
    title: "目前概率",
    width: "20%",
    index: "currently",
    center: "center",
    render: true,
  },
];
export const typeData = {
  total: 15,
  list: [
    {
      type: 1,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
    {
      type: 2,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
    {
      type: 3,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
    {
      type: 4,
      theory: 0.8,
      openNum: 10,
      currently: 2.33,
    },
  ],
};
