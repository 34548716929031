var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a-open-box-new"},[_c('div',{staticClass:"a-show-box"},_vm._l((_vm.imgList),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:('item.lv != undefined'),expression:"'item.lv != undefined'"}],key:index,staticClass:"img-item point",class:[
        item.lv != undefined
          ? `bg-${item.lv}`
          : item.bean > 1
          ? 'bg-1'
          : 'bg-4',
        { 'active-bg': _vm.chooseList.includes(item.id) },
      ],on:{"click":function($event){return _vm.chooseItemOnClick(item)}}},[(item.lv === 1 || item.type == 9)?_c('div',{staticClass:"bg-run"}):_vm._e(),_c('div',{staticClass:"alias"},[_vm._v(_vm._s(item.dura_alias))]),_c('div',{staticClass:"top-img-box"},[_c('img',{staticStyle:{"height":"90%"},attrs:{"src":item.cover,"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"price"},[_c('Crarity',{staticClass:"price-bean",attrs:{"price":(item.bean * 6).toFixed(0) === '0'
              ? 1
              : (item.bean * 6).toFixed(0)}})],1),(item.type == 9)?_c('div',{staticClass:"giving-icon"}):_vm._e()])}),0),_c('div',{staticClass:"bottom-box"},[_c('div',{staticClass:"confirm point",on:{"click":_vm.confirmFun}},[_vm._v("放入背包")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }