<template>
  <div>
    <div class="record-title">
      <div class="table-title">
        <div class="table-title-item" v-for="(t, i) in tableRecordList" :style="{ width: t.width, textAlign: t.center }" :key="i">
          <template v-if="t.render">
            {{ t.title }}
          </template>
          <template v-else>
            <slot name="tableTitle" :title="t.title" :i="i">
              {{ t.title }}</slot>
          </template>
        </div>
      </div>
      <div class="table-box" v-if="tableData.total!=0">
        <div class="table-item" v-for="(item, i) in tableData.list" :style="{height:item.height}" :key="i">
          <div :class="j.index" v-for="(j, k) in tableRecordList" :key="k" :style="{ width: j.width, textAlign: j.center }">
            <template v-if="!j.render">
              <div :style="j.style">
                <img v-if="j.img" :src="j.img.index" alt="" />
                <span v-if="j.leftVal">{{ j.leftVal }}</span>
                {{ item[j.index] }}
                <span v-if="j.rightVal">{{ j.rightVal }}</span>
              </div>
            </template>
            <template v-else>
              <slot :item="j.index" :data="item"></slot>
            </template>
          </div>
        </div>
      </div>
      <div class="nulldata" v-else>
        <div class="imgbox">
          <img src="@/assets/images/newuser/mb-table-nulldata.png" alt="">
          <div class="nulltext">暂无内容</div>
        </div>

      </div>
      <div class="tablepagination" v-if="tableData.total!=0">
        <Pagination  :total="tableData.total || total" :page.sync="tableData.current_page" :limit="tableData.pageSize ||tableData.pager|| pageSize" @pagination="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewTable',
  props: {
    pageSize: {
      type: [String, Number],
      default: 10
    },
    total: {
      type: [String, Number],
      default: 1
    },
    tableRecordList: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Object,
      default: () => { },
    },
  },
  created() {
    // console.log(this.tableData);
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {

    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val.page;
      this.$emit("pageChange", { page: this.currentPage });
    },
  }
}
</script>

<style lang="scss" scoped>
.record-title {
  width: 100%;
  .table-title {
    width: 100%;
    height: 0.4rem;
    display: flex;
    background: url("../../assets/images/newuser/mb-table-tabletitle.png")
      no-repeat;
    background-size: 100% 100%;
    align-items: center;
    .table-title-item {
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #9e9e9e;
      align-items: center;
    }
  }

  .table-box {
    width: 100%;

    .table-item {
      width: 100%;
      height: 0.45rem;
      display: flex;
      font-size: 0.1rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #cccccc;
      background: #08080f;
      opacity: 1;
      border: 0.01rem solid #2f2f5e;
      align-items: center;
    }
  }
  .nulldata {
    width: 100%;
    min-height: 2rem;
    max-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .imgbox {
      width: 0.8rem;
      height: 0.8rem;
      img {
        width: 100%;
      }
      .nulltext {
        text-align: center;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #c5bbed;
      }
    }
  }
  .tablepagination {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
}
</style>