<template>
  <div id="goods_box">
    <div class="pic position-relative">
      <img :src="cover" alt="" class="img img1" />
      <!-- <img class="img img2" :src="pic" alt=""> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "goods_box",
  props: {
    pic: {
      type: String,
    },
    cover: {
      type: String,
    },
  },

  filters: {},
  data() {
    return {
      Wpic: "",
    };
  },

  watch: {
    pic(v) {
      // this.Wpic = localStorage.getItem('static_file_url') + v
    },
  },
};
</script>

<style lang="scss">
#goods_box {
  width: 100%;
  height: 100%;
  // margin: 0.5rem auto 0.4rem;

  .pic {
    // width: 3.9rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .img {
      position: absolute;
      // max-height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .img1 {
      width: 100%;
      // max-width: 90%;
      // max-height: 100%;
      z-index: 0;
    }

    .img2 {
      width: 70%;
      z-index: 2;
      // max-width: 100%;
      // max-height: 100%;
    }
  }
}
</style>
